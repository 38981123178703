import { Component, OnInit, OnDestroy } from "@angular/core";
import { ContentService } from "./shared/content.service";
import { SharedService } from "./shared/shared.service";
import { NgcCookieConsentService, NgcInitializeEvent, NgcStatusChangeEvent, NgcNoCookieLawEvent } from 'ngx-cookieconsent';
import { Subscription } from "rxjs";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
	title = "app";

	private popupOpenSubscription: Subscription = new Subscription;
	private popupCloseSubscription: Subscription = new Subscription;
	private initializeSubscription: Subscription = new Subscription;
	private statusChangeSubscription: Subscription = new Subscription;
	private revokeChoiceSubscription: Subscription = new Subscription;
	private noCookieLawSubscription: Subscription = new Subscription;

	constructor(
		public contentService: ContentService,
		public shared: SharedService,
		private ccService: NgcCookieConsentService
	) {
		shared.content = contentService.getContent();
	}

	ngOnInit() {
		// subscribe to cookieconsent observables to react to main events
		this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
			() => {
				// you can use this.ccService.getConfig() to do stuff...
			});

		this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
			() => {
				// you can use this.ccService.getConfig() to do stuff...
			});

		this.initializeSubscription = this.ccService.initialize$.subscribe(
			(_event: NgcInitializeEvent) => {
				// you can use this.ccService.getConfig() to do stuff...
			});

		this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
			(_event: NgcStatusChangeEvent) => {
				// you can use this.ccService.getConfig() to do stuff...
			});

		this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
			() => {
				// you can use this.ccService.getConfig() to do stuff...
			});

		this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
			(_event: NgcNoCookieLawEvent) => {
				// you can use this.ccService.getConfig() to do stuff...
			});
	}

	ngOnDestroy() {
		// unsubscribe to cookieconsent observables to prevent memory leaks
		this.popupOpenSubscription.unsubscribe();
		this.popupCloseSubscription.unsubscribe();
		this.initializeSubscription.unsubscribe();
		this.statusChangeSubscription.unsubscribe();
		this.revokeChoiceSubscription.unsubscribe();
		this.noCookieLawSubscription.unsubscribe();
	}
}
