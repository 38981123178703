import { Component } from "@angular/core";

import { AppInfoService } from "../../../shared";
import {
	trigger,
	state,
	style,
	animate,
	transition,
} from '@angular/animations';
import { SharedService } from "app/shared/shared.service";

@Component({
	selector: "app-programs",
	templateUrl: "./programs.component.html",
	styleUrls: ["./programs.component.scss"],
	animations: [
		trigger('toggle', [
			state('opened', style({
				height: '*',
			})),

			state('closed', style({
				height: '30px',
			})),

			transition('opened => *', [
				animate('250ms ease-in')
			]),

			transition('closed => *', [
				animate('250ms ease-out')
			]),
		]),
	]
})
export class ProgramsComponent {
	title = this.appInfo.title;
	activeToggle = -1;

	constructor(
		private appInfo: AppInfoService,
		public shared: SharedService,
	) { }

	toggle(toggleIndex: number) {
		if (this.activeToggle === toggleIndex) {
			this.activeToggle = -1;
		} else {
			this.activeToggle = toggleIndex;
		}
	}
}
