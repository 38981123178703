import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AREAS_COMPONENTS } from './areas/index';
import { AppSharedModule } from './shared';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScrollToModule } from 'ng2-scroll-to';
import { ModalSuccessComponent } from './areas/sections/modalSuccess/modalSuccess.component';
import { NgsRevealModule } from 'ngx-scrollreveal';
import { HomeResolver } from './areas/home.resolver';
import { SafePipe } from './shared/safe-pipe.pipe';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';

const cookieConfig: NgcCookieConsentConfig = {
	cookie: {
		domain: '' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
	},
	palette: {
		popup: {
			background: '#222'
		},
		button: {
			background: '#f1d600'
		}
	},
	elements: {
		// tslint:disable-next-line:max-line-length
		allow: `<a aria-label="allow cookies" role="button" tabindex="0" class="cc-btn cc-allow" style="background-color: #0090da; color: #fff">Elfogadom</a>`,
		deny: '<a aria-label="deny cookies" role="button" tabindex="0" class="cc-btn cc-deny">Elutasítom</a>',
		dismiss: `<a aria-label="deny cookies" role="button" tabindex="0" class="cc-btn cc-deny">Rendben</a>`,
		messagelink:
		// tslint:disable-next-line:max-line-length
		`<span id="cookieconsent:desc" class="cc-message">{{message}} <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank">{{cookiePolicyLink}}</a></span>`,
	},
	content: {
		// tslint:disable-next-line:max-line-length Cookie_Kezelési_Szabályzat_ZYP.PDF
		message: 'A weboldalon sütiket (cookie-kat) használunk, hogy biztonságos böngészés mellett a legjobb felhasználói élményt nyújthassuk látogatóinknak.',

		cookiePolicyLink: 'Elolvasom!',
		cookiePolicyHref: '/assets/pdfs/ZYP_cookie.PDF',
	}
};

@NgModule({
	entryComponents: [
		ModalSuccessComponent,
	],
	declarations: [AppComponent, ...AREAS_COMPONENTS, SafePipe],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		BrowserAnimationsModule,
		AppRoutingModule,
		AppSharedModule,
		NgbModule,
		HttpClientModule,
		ReactiveFormsModule,
		FormsModule,
		ScrollToModule,
		NgsRevealModule,
		ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
		NgcCookieConsentModule.forRoot(cookieConfig),
	],
	providers: [
		HomeResolver,
	],
	bootstrap: [AppComponent],
})
export class AppModule { }
